<template>
  <div class="appointment">
    <img src="../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack">
    <div :class="[showContent?'':'full-left','left']">
      <!-- <div class="title">预约参观</div>
      <div class="text">appointment</div> -->
      <div class="contents">
        <div class="content" v-for="(item, index) in usersData" :key="index">
          <div class="info-left">
            <img :src="QINIU_CDN_HOST + item.head_img" alt="" class="head" v-if="item.head_img != '0'">
            <!-- <img src="../assets/image/back_icon.png" alt="" class="head"> -->
            <div class="name-position">
              <div class="name">{{item.real_name}}</div>
              <div class="position">{{item.title}}</div>
            </div>
          </div>
          <div class="info-right">{{item.phone}}</div>
        </div>
      </div>
    </div>
    <div class="right" v-if="showContent">
      <div class="right-content">
        <div class="title">预约参观</div>
        <div class="content">
          <div class="box">
            <div class="text"><img src="../assets/image/require_cion.png" alt="">预计到访日期</div>
            <el-date-picker v-model="date" type="date" placeholder="" class="input date" value-format="yyyy-MM-dd">
            </el-date-picker>
            <!-- <input type="text" class="input" placeholder="预计到访日期" v-model="date"> -->
          </div>
          <div class="box">
            <div class="text"><img src="../assets/image/require_cion.png" alt="">您的联系方式</div>
            <input type="number" class="input" placeholder="" v-model="phone" ref="phone">
          </div>
          <div class="box">
            <div class="text">您的办公面积</div>
            <el-select v-model="area" placeholder="" class="input area-input">
              <el-option v-for="(item, index) in areaOptions" :label="item.label" :value="item.label" :key="index">
              </el-option>
            </el-select>
            <!-- <input type="text" class="input" placeholder="您需要的办公面积" v-model="area"> -->
          </div>
          <div class="box">
            <div class="text"><img src="../assets/image/require_cion.png" alt="">公司名称</div>
            <div class="company-box">
              <input type="text" class="input" placeholder="" v-model="company">
              <div class="types">
                <div class="type" @click="handleChangeType('1')"><img
                    :src="appointment_type=='1'?require('../assets/image/radio_selected.png'):require('../assets/image/radio_icon.png')"
                    alt="">自用</div>
                <div class="type" @click="handleChangeType('2')"><img
                    :src="appointment_type=='2'?require('../assets/image/radio_selected.png'):require('../assets/image/radio_icon.png')"
                    alt="">代理</div>
              </div>
            </div>
          </div>
        </div>
        <div class="submit-btn" @click="handleSubmit">提交预约信息</div>
      </div>

      <div class="pop" v-if="popIsShow">
        <div class="pop-text">
          {{msg}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        usersData: [],
        area: "",
        date: "",
        phone: "",
        company: "",
        appointment_type: '1',
        msg: "",
        popIsShow: false,
        dateTime: new Date(),
        areaOptions: [],
        showContent: false,
        beforePage:false
      };
    },
    beforeRouteEnter(to,from,next) {
        if (from.name == null) {
            next( vm => {
                vm.beforePage = true
            })
        }else{
          next()
        }
    },
    methods: {
      handleGoBack() {
        if(!this.beforePage){
          this.$router.go(-1)
        }else{
          this.$router.push({
            path:"/"
          })
        }
      },
      handleChangeTime() {
        this.date = this.dateToString(this.dateTime);
      },
      handleChangeType(params) {
        this.appointment_type = params;
      },
      getData() {
        this.$axios({
          method: "POST",
          url: "api/v5/panorama/channelDetails",
          data: {
            panorama_id: this.$route.query.panorama_id,
            channel_id: this.$route.query.channel_id
          }
        }).then(res => {
          if (res.data.status == '200') {
            this.usersData = res.data.data.content;
            this.showContent = res.data.data.userType
            for (var i = 0; i < res.data.data.areaSearch.length; i++) {
              this.areaOptions.push({
                value: i,
                label: res.data.data.areaSearch[i]
              })
            }
          }
        })
      },
      dateToString(date) {
        date = new Date(date);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
      },
      handleSubmit() {
        if (this.date != '' && this.phone && this.company && this.date != null) {
          if ((/^1\d{10}$/.test(this.phone))) {
            this.$axios({
              method: "post",
              url: "api/v5/panorama/appointment",
              data: {
                panorama_id: this.$route.query.panorama_id,
                date_of_visit_time: this.date,
                phone: this.phone,
                demand_space: this.area,
                appointment_type: this.appointment_type,
                company_name: this.company
              }
            }).then(res => {
              this.msg = res.data.msg;
              this.popIsShow = true;
              if (res.data.status == '200') {
                this.area = '';
                this.date = '';
                this.phone = '';
                this.company = '';
                this.appointment_type = '1';
                let that = this;
                setTimeout(() => {
                  that.popIsShow = false;
                  that.$router.go(-1)
                }, 700)

              } else {
                let that = this;
                setTimeout(() => {
                  that.popIsShow = false
                }, 1000)
              }

            })
          } else {
            alert('请输入正确的手机号!');
            this.$refs.phone.focus();
          }

        } else {
          alert('请填写相关必填信息')
        }
      }
    },
    created() {
      this.getData();
    },
  };
</script>
<style>
  .appointment .el-input__inner {
    width: 100%;
    height: 100%;
    text-indent: 1em;
    padding: 0;
    border: none;
    background: #f1f1f1;
  }

  .appointment .el-input {
    width: 100%;
    height: 100%;
    font-size: 20px;
    color:
  }

  .appointment .el-input__icon {
    display: none;
  }
</style>
<style scoped lang='scss'>
  .appointment {
    width: 100vw;
    height: 100vh;
    display: flex;
    background: url('../assets/image/bg.jpg') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    .go-back {
      position: absolute;
      width: 44px;
      height: 36px;
      top: 77px;
      left: 72px;
      cursor: pointer;
      z-index: 2;
    }

    .left {
      position: relative;
      width: 1125px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      .title {
        margin-top: 236px;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }

      .text {
        margin-top: 14px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .contents {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 552px;
        height: 240px;
        transform: translate(-50%, -50%);
        overflow-y: auto;

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 80px;
          box-sizing: border-box;

          .info-left {
            display: flex;
            align-items: center;

            .head {
              margin-right: 30px;
              width: 64px;
              height: 64px;
              border-radius: 32px;
            }

            .name-position {
              .name {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #fff;
              }

              .position {

                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #b3b3b3;
              }
            }
          }

          .info-right {
            position: relative;
            font-size: 30px;
            /* font-family: PingFangSC-Regular, PingFang SC; */
            font-weight: 400;
            color: #fff;

            &::before {
              position: absolute;
              top: 50%;
              left: -51px;
              transform: translateY(-50%);
              content: "";
              width: 31px;
              height: 31px;
              background: url("../assets/image/new_phone_icon.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }

    .full-left {
      width: 100%;
    }

    .right {
      position: relative;
      display: flex;
      
      align-items: center;
      padding: 0 120px;
      width: 795px;
      /* transform: translateY(50%); */
      height: 100vh;
      box-sizing: border-box;
      background: #fff;
      .right-content{
        width:100%;
      }
      .pop {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        text-align: center;
        background: rgba(33, 33, 33, .3);

        .pop-text {
          width: 400px;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 18px;
          font-weight: 600px;
          background: #fff;
          color: #333;
        }
      }

      .title {
        font-size: 50px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        /* border-bottom: 1px solid #e2e2e2; */
      }

      .content {
        .box {
          .text {
            margin: 29px 0 16px 0;
            font-size: 27px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            vertical-align: top;
            color: #333;

            img {
              margin-right: 10px;
              margin-top: 8px;
              width: 11px;
              vertical-align: top;
            }
          }

          .company-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .input {
              width: 65%;
            }

            .types {
              display: flex;
              width: 35%;

              .type {
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;

                img {
                  margin: 0 12px;
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }

        .input {
          width: 100%;
          height: 70px;
          border: none;
          background: #f1f1f1;
          font-size: 20px;
          text-indent: 1em;
        }

        .date {
          position: relative;
          text-indent: 0;

          &:after {
            position: absolute;
            top: 50%;
            right: 36px;
            width: 38px;
            height: 36px;
            transform: translateY(-50%);
            content: "";
            background: url('../assets/image/calendar.png') no-repeat;
            background-size: 100% 100%;
          }
        }

        .area-input {
          position: relative;
          text-indent: 0;

          &:after {
            position: absolute;
            top: 50%;
            right: 45px;
            width: 17px;
            height: 15px;
            transform: translateY(-50%);
            content: "";
            background: url('../assets/image/select_icon.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .submit-btn {
        margin-top: 40px;
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 0px 2px 4px rgba(178, 178, 178, 0.5);
        background: #333;
        cursor: pointer;
      }
    }
  }
</style>